/** @jsx jsx */
import { graphql } from "gatsby";
import { Box, Container, Heading, jsx } from "theme-ui";
import ContentWithImage from "../../components/ContentWithImage";
import HexagonList from "../../components/HexagonList";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const RoofCoating = ({ data }) => {
  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="roof coating"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerFancyBanner={false}
      headerLinkButtonEnabled={true}
    >
      <Seo title="Roof Coating" />

      <Box as="article" sx={{ my: [8, null, 11] }}>
        <Container>
          <ContentWithImage
            imageAlt="man coating a roof with sealant"
            imageURL={data.MaintenanceServices.childImageSharp.gatsbyImageData}
            titleText="Roof Coating Services in Phoenix, Arizona"
          >
            <p>
              For many flat roofs, coating can significantly delay the need for
              replacement. It can help waterproof flat roofs, seal down areas
              that are deteriorating, and help your roofing materials better
              resist UV radiation. There are several different types of roof
              coating: silicone, acrylic, polyurethane, and more. They all serve
              different purposes, and which one your roof needs usually depends
              on the level and type of damage it already has.
            </p>

            <p>
              If your roof needs a lifetime extension, or if you want to prevent
              future damage, Legacy Repair’s specialists will help you keep your
              roof strong, well-sealed, and ready for unpredictable Arizona
              weather.
            </p>

            <Heading as="h2" variant="styles.h3" sx={{ mt: 6 }}>
              Why Use a Professional Roof Repair Service for Coating?
            </Heading>

            <p>
              People often try to save money by re-coating their roofs
              themselves, especially when it comes to flat roofs. Not only is
              this much more dangerous than it may seem, it’s also risky for
              your roof. An unprofessionally-coated roof can fail to protect
              your underlayment by missing invisible damage. Even worse, if the
              wrong coating is used, it can do much more damage than good -
              leading to tears and other problems that require a full roof
              replacement.
            </p>

            <p>
              By choosing a certified repair company with long-term experience
              in Arizona, you can become more informed about what your roof
              needs while staying safe and resting assured that it is truly
              protected.
            </p>
          </ContentWithImage>
        </Container>
      </Box>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "man-coating-a-roof.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    RoofTopsIcon: file(relativePath: { eq: "roof-tops.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    MaintenanceServices: file(
      relativePath: { eq: "man-coating-a-roof-with-sealant.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default RoofCoating;
